import { SERVER } from 'config';
import { xxTest } from './api';

async function getNewsletterTrackAPI(nID: string) {
  const xx = await xxTest().get(`/newsletter/getNewsletterTrackAPI/${nID}`);
  const json = xx.data;
  return json;
}

async function getNewsletterUnsubAPI(nID: string) {
  const xx = await xxTest().get(`/newsletter/getNewsletterUnsubAPI/${nID}`);
  const json = xx.data;
  return json;
}

export { getNewsletterTrackAPI, getNewsletterUnsubAPI };
