// material
import { Container, Box, Alert } from '@material-ui/core';
// components
import React, { useEffect, useState } from 'react';
import { getNewsletterTrackAPI, getNewsletterUnsubAPI } from '_apis_/newsletter';
// hooks
import { useParams } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import '../../angebot.css';
import logovisiqs from '../../assets/img/logo_visiqs_big.png';

// ----------------------------------------------------------------------

export default function Newsletter() {
  const { themeStretch } = useSettings();
  const params = useParams();
  const title = 'Visiqs - Newsletter';

  useEffect(() => {
    async function getAdsInfo() {
      if (params.mode == 'trck') {
        await getNewsletterTrackAPI(params.id);
      }
      if (params.mode == 'manage') {
        await getNewsletterUnsubAPI(params.id);
      }
    }
    getAdsInfo();
  }, []);

  if (params.mode == null) {
    return <>Keine Funktion</>;
  }

  if (params.mode == 'trck') {
    return <></>;
  }
  if (params.mode == 'manage') {
    return (
      <Box sx={{ width: '100%', padding: '60px 0px 60px 0px' }}>
        <Container>
          <img
            src={logovisiqs}
            height={90}
            width="auto"
            style={{
              display: 'block',
              margin: '0 auto'
            }}
          />
          <Box sx={{ maxWidth: 480, mx: 'auto', textAlign: 'center' }}>
            <Alert severity="success">Erfolgreich abgemeldet</Alert>
          </Box>
        </Container>
      </Box>
    );
  }

  return <>Keine Funktion</>;
}
