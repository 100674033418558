// routes
import StorageIcon from '@material-ui/icons/Storage';
import TextSnippetIcon from '@material-ui/icons/TextSnippet';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import GroupIcon from '@material-ui/icons/Group';
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  tätigkeit: getIcon('ic_assignment_outline'),
  hosting: <StorageIcon />,
  texte: <TextSnippetIcon />,
  besucher: <PersonPinIcon />,
  smm: <GroupIcon />
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Aufträge',
    items: [
      {
        title: 'start',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'OSM', path: PATH_DASHBOARD.general.seo, icon: ICONS.analytics },
      {
        title: 'KI Marketing',
        path: PATH_DASHBOARD.general.ki,
        icon: <i className="fa-solid fa-brain" />
      },
      { title: 'besucher', path: PATH_DASHBOARD.general.besucher, icon: ICONS.besucher },
      { title: 'hosting', path: PATH_DASHBOARD.general.hosting, icon: ICONS.hosting },
      { title: 'Content', path: PATH_DASHBOARD.general.texte, icon: ICONS.texte },
      {
        title: 'social media',
        path: PATH_DASHBOARD.general.smm,
        icon: <i className="fa-brands fa-facebook" />
      },
      {
        title: 'Google Ads',
        path: PATH_DASHBOARD.general.googleAds,
        icon: <i className="fa-brands fa-google" />
      },
      { title: 'Retargeting', path: PATH_DASHBOARD.general.retargeting, icon: ICONS.booking },
      {
        title: 'Projekte',
        path: PATH_DASHBOARD.general.projekt,
        icon: <i className="fa-solid fa-diagram-project" />
      },
      {
        title: 'Backlinks',
        path: PATH_DASHBOARD.general.backlinks,
        icon: <i className="fa-solid fa-link" />
      },
      {
        title: 'Website Monitor',
        path: PATH_DASHBOARD.general.monitoring,
        icon: <i className="fa-solid fa-eye" />
      }
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'weiteres',
    items: [
      // MANAGEMENT : USER
      { title: 'Tätigkeit', path: PATH_DASHBOARD.general.tätigkeit, icon: ICONS.booking },
      {
        title: 'hilfe & Rechnungen',
        path: PATH_DASHBOARD.general.hilfe,
        icon: ICONS.chat
      }

      // MANAGEMENT : E-COMMERCE
      /* {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },

      // MANAGEMENT : BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts },
          { title: 'post', path: PATH_DASHBOARD.blog.postById },
          { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
        ]
      } */
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  /* {
    subheader: 'app',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">2</Label>
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'kanban',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban
      }
    ]
  } */
];

export default sidebarConfig;
