import { SERVER } from 'config';
import { xxTest } from './api';

async function getTermineByPID(data: any) {
  const xx = await xxTest().post(`/termineAPI/getTermineByPID/`, data);
  return xx.data;
}

async function bucheTermin(data: any) {
  const xx = await xxTest().post(`/termineAPI/bucheTermin/`, data);
  return xx.data;
}

export { getTermineByPID, bucheTermin };
