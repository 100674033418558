// hooks
import { GoogleOAuthProvider } from '@react-oauth/google';
import useSettings from 'hooks/useSettings';
import useAuth from './hooks/useAuth';
// theme
import ThemeConfig from './theme';
// routes
import Router from './routes';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  const { changeMode } = useSettings();
  const urlString = window.location.href;
  const url = new URL(urlString);
  if (url.searchParams.get('x') == 'f') {
    changeMode('dark', 'default');
  }
  return (
    <GoogleOAuthProvider clientId="956400289459-p29lfbrbsd6vev734kh4sf750fs6se33.apps.googleusercontent.com">
      <ThemeConfig>
        <ThemePrimaryColor>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                {/* <Settings /> */}
                <ScrollToTop />
                <GoogleAnalytics />
                {isInitialized ? <Router /> : <LoadingScreen />}
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </ThemeConfig>
    </GoogleOAuthProvider>
  );
}
