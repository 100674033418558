import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const api_url = process.env.REACT_APP_SERVER_URL;
// const api_url = 'http://localhost:3009';

const token = localStorage.getItem('accessToken');

function xxTest() {
  const token = localStorage.getItem('accessToken');
  const apiClient = axios.create({
    baseURL: api_url,
    headers: {
      authorization: `Bearer ${token}`
    }
  });
  return apiClient;
}

function passwordResetApi() {
  const apiClient = axios.create({
    baseURL: api_url
  });
  return apiClient;
}

function apiPost() {
  const token = localStorage.getItem('accessToken');
  const apiClient = axios.create({
    baseURL: api_url,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`
    }
  });
  return apiClient;
}

const apiClient = axios.create({
  baseURL: api_url
});

function getDownloadClient() {
  const token = localStorage.getItem('accessToken');

  const downloadClient = axios.create({
    baseURL: api_url,
    responseType: 'blob',
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`
    }
  });
  return downloadClient;
}

export { apiClient, getDownloadClient, xxTest, apiPost, passwordResetApi };
