import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation, useParams } from 'react-router-dom';
import { apiClient } from '_apis_/api';
import { setSession } from 'utils/jwt';
import useSettings from 'hooks/useSettings';
// layouts
import Newsletter from 'pages/dashboard/Newsletter';
import TermineUI from 'pages/dashboard/TermineUI';
import { Button } from '@material-ui/core';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const ApiLogin = () => {
  const token = useParams();
  console.log(token);
  const [isLogIn, setisLogIn] = useState(false);
  useEffect(() => {
    const doLogin = async () => {
      const response = await apiClient.get(`/auth/login365Api/${token.id}/${token.pass}`);
      const res = response.data;
      const accessToken = res.tokens.access.token;
      const user = res.kunde[0];
      const xx = await setSession(accessToken);
      setisLogIn(true);
    };
    doLogin();
  }, []);

  if (isLogIn) {
    return <Button href="/">Weiter</Button>;
    // return <Navigate to="/dashboard/app" replace />;
  }
  return <>warte</>;
};

export default function Router() {
  return useRoutes([
    {
      path: 'loginAPI/:id/:pass',
      element: <ApiLogin />
    },
    {
      path: 'angebote/:id/:pin',
      element: <Angebote />
    },
    {
      path: 'newsletter/:mode/:id',
      element: <Newsletter />
    },
    {
      path: 'TermineUI/:id',
      element: <TermineUI />
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <DashboardLayout />
        </GuestGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },

        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'seo', element: <Seo /> },
        { path: 'texte', element: <TextUebersicht /> },
        { path: 'besucher', element: <Besucher /> },
        { path: 'retargeting', element: <Retargeting /> },
        { path: 'hosting', element: <Hosting /> },
        { path: 'smm', element: <SMM /> },
        { path: 'hilfe', element: <Hilfe /> },
        { path: 'taetigkeit', element: <Taetigkeit /> },
        { path: 'backlinks', element: <Backlinks /> },
        { path: 'projekt', element: <Projekt /> },
        {
          path: 'projektdetails',
          element: <LogoOnlyLayout />,
          children: [
            { path: '/', element: <Projekt /> },
            { path: ':pr_id', element: <ProjektDetails /> }
          ]
        },
        { path: 'monitoring', element: <Monitoring /> },
        { path: 'google-ads', element: <GoogleAds /> },
        { path: 'ki', element: <Ki /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        {
          path: 'components',
          children: [
            { path: '/', element: <ComponentsOverview /> },
            // FOUNDATIONS
            { path: 'color', element: <Color /> },
            { path: 'typography', element: <Typography /> },
            { path: 'shadows', element: <Shadows /> },
            { path: 'grid', element: <Grid /> },
            { path: 'icons', element: <Icons /> },
            // MATERIAL UI
            { path: 'accordion', element: <Accordion /> },
            { path: 'alert', element: <Alert /> },
            { path: 'autocomplete', element: <Autocomplete /> },
            { path: 'avatar', element: <Avatar /> },
            { path: 'badge', element: <Badge /> },
            { path: 'breadcrumbs', element: <Breadcrumb /> },
            { path: 'buttons', element: <Buttons /> },
            { path: 'checkbox', element: <Checkbox /> },
            { path: 'chip', element: <Chip /> },
            { path: 'dialog', element: <Dialog /> },
            { path: 'label', element: <Label /> },
            { path: 'list', element: <List /> },
            { path: 'menu', element: <Menu /> },
            { path: 'pagination', element: <Pagination /> },
            { path: 'pickers', element: <Pickers /> },
            { path: 'popover', element: <Popover /> },
            { path: 'progress', element: <Progress /> },
            { path: 'radio-button', element: <RadioButtons /> },
            { path: 'rating', element: <Rating /> },
            { path: 'slider', element: <Slider /> },
            { path: 'snackbar', element: <Snackbar /> },
            { path: 'stepper', element: <Stepper /> },
            { path: 'switch', element: <Switches /> },
            { path: 'table', element: <Table /> },
            { path: 'tabs', element: <Tabs /> },
            { path: 'textfield', element: <Textfield /> },
            { path: 'timeline', element: <Timeline /> },
            { path: 'tooltip', element: <Tooltip /> },
            { path: 'transfer-list', element: <TransferList /> },
            { path: 'tree-view', element: <TreeView /> },
            { path: 'data-grid', element: <DataGrid /> },
            // EXTRA COMPONENTS
            { path: 'chart', element: <Charts /> },
            { path: 'map', element: <Map /> },
            { path: 'editor', element: <Editor /> },
            { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
            { path: 'upload', element: <Upload /> },
            { path: 'carousel', element: <Carousel /> },
            { path: 'multi-language', element: <MultiLanguage /> },
            { path: 'animate', element: <Animate /> },
            { path: 'mega-menu', element: <MegaMenu /> },
            { path: 'form-validation', element: <FormValidation /> }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/Start')));
const Angebote = Loadable(lazy(() => import('../pages/dashboard/Angebote')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const Hosting = Loadable(lazy(() => import('../pages/dashboard/Hosting')));
const TextUebersicht = Loadable(lazy(() => import('../pages/dashboard/TextUebersicht')));
const Seo = Loadable(lazy(() => import('../pages/dashboard/Seo')));
const SMM = Loadable(lazy(() => import('../pages/dashboard/SocialMedia')));
const Hilfe = Loadable(lazy(() => import('../pages/dashboard/Hilfe')));
const Taetigkeit = Loadable(lazy(() => import('../pages/dashboard/Taetigkeit')));
const Besucher = Loadable(lazy(() => import('../pages/dashboard/Besucher')));
const Retargeting = Loadable(lazy(() => import('../pages/dashboard/Retargeting')));
const Backlinks = Loadable(lazy(() => import('../pages/dashboard/Backlinks')));
const Projekt = Loadable(lazy(() => import('../pages/dashboard/Projekt')));
const ProjektDetails = Loadable(lazy(() => import('../pages/dashboard/ProjektDetails')));
const Monitoring = Loadable(lazy(() => import('../pages/dashboard/Monitoring')));
const GoogleAds = Loadable(lazy(() => import('../pages/dashboard/GoogleAds')));
const Ki = Loadable(lazy(() => import('../pages/dashboard/Ki')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')));
const Color = Loadable(
  lazy(() => import('../pages/components-overview/foundations/FoundationColors'))
);
const Typography = Loadable(
  lazy(() => import('../pages/components-overview/foundations/FoundationTypography'))
);
const Shadows = Loadable(
  lazy(() => import('../pages/components-overview/foundations/FoundationShadows'))
);
const Grid = Loadable(
  lazy(() => import('../pages/components-overview/foundations/FoundationGrid'))
);
const Icons = Loadable(
  lazy(() => import('../pages/components-overview/foundations/FoundationIcons'))
);
const Accordion = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/Accordion'))
);
const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/Autocomplete'))
);
const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/Breadcrumb'))
);
const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/Checkboxes'))
);
const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/Pagination'))
);
const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/RadioButtons'))
);
const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/textfield'))
);
const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
const TransferList = Loadable(
  lazy(() => import('../pages/components-overview/material-ui/transfer-list'))
);
const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
const DataGrid = Loadable(lazy(() => import('../pages/components-overview/material-ui/data-grid')));
//
const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(
  lazy(() => import('../pages/components-overview/extra/CopyToClipboard'))
);
const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(
  lazy(() => import('../pages/components-overview/extra/MultiLanguage'))
);
const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
const FormValidation = Loadable(
  lazy(() => import('../pages/components-overview/extra/form-validation'))
);
