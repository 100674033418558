// material
import {
  Container,
  Box,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Stack
} from '@material-ui/core';
import { ArrowBack, Call, Event, Timelapse } from '@material-ui/icons';
// components
import React, { useEffect, useState } from 'react';
import { getTermineByPID, bucheTermin } from '_apis_/termineAPI';

import {
  DatePicker,
  StaticDatePicker,
  MobileDatePicker,
  DesktopDatePicker,
  LocalizationProvider
} from '@material-ui/lab';
import isWeekend from 'date-fns/isWeekend';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/de';

// hooks
import { useParams } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import '../../angebot.css';
import logovisiqs from '../../assets/img/logo_visiqs_big.png';

// ----------------------------------------------------------------------

export default function TermineUI() {
  const params = useParams();
  const [step, setStep] = React.useState(0);
  const [selectedDate, setselectedDate] = React.useState(null);
  const [selectedTime, setselectedTime] = React.useState(null);
  const [selTimes, setselTimes] = React.useState([]);
  const [terminName, setterminName] = React.useState('');
  const [terminPhone, setterminPhone] = React.useState('');
  const [terminMail, setterminMail] = React.useState('');
  const [terminWebsite, setterminWebsite] = React.useState('');

  useEffect(() => {
    async function getAdsInfo() {
      //
    }
    getAdsInfo();
    moment.locale('de', { week: { dow: 3 } });
  }, []);

  useEffect(() => {
    async function getFreieTermine() {
      const data = await getTermineByPID({
        terminID: params.id,
        terminDate: moment(selectedDate).format('YYYY-MM-DD')
      });
      setselTimes(data);
    }
    if (selectedDate !== null) {
      getFreieTermine();
    }
  }, [selectedDate]);

  if (params.id == null) {
    return <>Keine Funktion</>;
  }

  return (
    <div style={{ padding: '5px' }}>
      <Card style={{ width: '380px', overflowX: 'hidden', padding: '0' }}>
        <CardHeader title="Termin buchen" />
        <CardContent style={{}}>
          <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'left' }}>
            <div>
              <div style={{ display: step === 0 ? 'block' : 'none', animation: 'fadeIn 0.5s' }}>
                <StaticDatePicker
                  label="For desktop"
                  value={selectedDate}
                  showToolbar={false}
                  minDate={new Date()}
                  maxDate={moment().add('40', 'days').toDate()}
                  shouldDisableDate={isWeekend}
                  onChange={(newValue: any) => {
                    setselectedDate(newValue);
                    setStep(1);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} margin="normal" />}
                />
              </div>
              <div
                style={{
                  display: step === 1 ? 'block' : 'none',
                  animation: 'fadeIn 0.5s',
                  padding: '8px'
                }}
              >
                <IconButton
                  onClick={() => {
                    setStep(0);
                    setselectedDate(null);
                    setselectedTime(null);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                {selectedDate !== null && (
                  <>Meeting am {moment(selectedDate).format('DD.MM.YYYY')}</>
                )}
                <div style={{ height: '300px', overflowY: 'scroll' }}>
                  {selTimes.map((sd: any) => (
                    <Button
                      variant="outlined"
                      key={sd.uts}
                      fullWidth
                      style={{ margin: '5px 0px' }}
                      onClick={() => {
                        setselectedTime(sd.start_time);
                        setStep(2);
                      }}
                    >
                      {moment(sd.start_time).format('HH:mm')}
                    </Button>
                  ))}
                </div>
              </div>
              <div
                style={{
                  display: step === 2 ? 'block' : 'none',
                  animation: 'fadeIn 0.5s',
                  padding: '8px'
                }}
              >
                <IconButton
                  onClick={() => {
                    setStep(0);
                    setselectedDate(null);
                    setselectedTime(null);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                {selectedDate !== null && (
                  <>Meeting am {moment(selectedTime).format('DD.MM.YYYY, HH:mm')}</>
                )}
                <TextField
                  fullWidth
                  onChange={(e) => setterminName(e.target.value)}
                  required
                  size="small"
                  name="Name"
                  placeholder="Name"
                  variant="outlined"
                  style={{ margin: '5px 0px' }}
                />
                <TextField
                  fullWidth
                  onChange={(e) => setterminMail(e.target.value)}
                  required
                  size="small"
                  name="Mail"
                  placeholder="Mail"
                  variant="outlined"
                  style={{ margin: '5px 0px' }}
                />
                <TextField
                  fullWidth
                  onChange={(e) => setterminPhone(e.target.value)}
                  required
                  size="small"
                  name="Phone"
                  placeholder="Telefon"
                  variant="outlined"
                  style={{ margin: '5px 0px' }}
                />
                <TextField
                  fullWidth
                  onChange={(e) => setterminWebsite(e.target.value)}
                  required
                  size="small"
                  name="Website"
                  placeholder="Website"
                  variant="outlined"
                  style={{ margin: '5px 0px' }}
                />
                <p style={{ fontSize: '9px', lineHeight: '9px', margin: '5px 0px' }}>
                  Indem Sie fortfahren, bestätigen Sie, dass Sie die die Visiqs{' '}
                  <a href="https://visiqs.com/Datenschutz" target="_blank">
                    Datenschutzerklärung
                  </a>{' '}
                  gelesen und akzeptiert haben.
                </p>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{ margin: '5px 0px' }}
                  onClick={async () => {
                    setStep(3);
                    await bucheTermin({
                      terminMail,
                      terminName,
                      terminPhone,
                      terminWebsite,
                      selectedTime,
                      tID: params.id
                    });
                  }}
                >
                  Termin buchen
                </Button>
              </div>

              <div
                style={{
                  display: step === 3 ? 'block' : 'none',
                  animation: 'fadeIn 0.5s',
                  padding: '8px'
                }}
              >
                Ihre Terminanfrage ist eingegangen, wir melden uns in Kürze bei Ihnen!
                <Stack alignItems="center" direction="row" gap={1} mt={1}>
                  <Event />
                  <Typography variant="body1">
                    {moment(selectedTime).format('DD.MM.YYYY, HH:mm')}
                  </Typography>
                </Stack>
                <Stack alignItems="center" direction="row" gap={1}>
                  <Timelapse />
                  <Typography variant="body1">ca. 30 - 45 Minuten</Typography>
                </Stack>
                <Stack alignItems="center" direction="row" gap={1}>
                  <Call />
                  <Typography variant="body1">Google Meet / Telefon</Typography>
                </Stack>
              </div>
            </div>
          </Paper>
        </CardContent>
      </Card>
    </div>
  );
}
