import { v4 as uuidv4 } from 'uuid';
// utils
import fakeRequest from '../utils/fakeRequest';
import { verify, sign } from '../utils/jwt';
// @types
import { User } from '../@types/account';
//
import mock from './mock';

import { SERVER, JWTS } from '../config';
import { xxTest } from './api';

// ----------------------------------------------------------------------

const JWT_SECRET = JWTS;

mock.onGet('/api/account/my-account').reply(async (config) => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Authorization token missing' }];
    }

    const accessToken = Authorization.split(' ')[1];
    const data: any = verify(accessToken, JWT_SECRET!);
    const userId = typeof data === 'object' ? data?.id : '';
    // const user = users.find((_user) => _user.id === userId);

    const user = await xxTest().get(`/kunde/${userId}`);

    if (!user) {
      return [401, { message: 'Invalid authorization token' }];
    }

    return [200, user.data[0]];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
